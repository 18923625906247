@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

/* You can override the default Infima variables here. */
:root {
  --fbn-dark-teal: #143840;
  --fbn-light-teal: #2B6264;
  --fbn-light-blue: #9ECFD1;
  --fbn-white: #FFFFFF;
  --fbn-orange: #FF4B31;

  --ifm-background-color: var(--fbn-white);
  --ifm-color-primary: var(--fbn-orange);
  --ifm-color-primary-dark: #cd3d28;
  --ifm-color-primary-darker: #c93019;
  --ifm-color-primary-darkest: #a52613;
  --ifm-color-primary-light: #f3543d;
  --ifm-color-primary-lighter: #f16753;
  --ifm-color-primary-lightest: #e97c6c;
  --ifm-code-font-size: 100%;
  --ifm-font-family-base: Roboto, sans-serif;
  --ifm-menu-link-padding-horizontal: 0.5rem;
  --ifm-navbar-background-color: var(--fbn-light-teal);
  --ifm-navbar-link-color: var(--ifm-color-content-inverse);
  --ifm-pre-padding: 0;
  --doc-sidebar-width: 350px !important;
}

html[data-theme="light"] {
  nav.navbar--fixed-top {
    color: var(--ifm-color-content-inverse);
  }

  .navbar-sidebar {
    &__item {
      background-color: var(--ifm-background-color);
      color: var(--ifm-color-content);
    }
  }
}

/* For readability concerns, you should choose a lighter palette in dark mode. */
html[data-theme="dark"] {
  --ifm-background-color: #050d10;
  --ifm-background-surface-color: #0C2227;
  --ifm-card-background-color: var(--ifm-color-emphasis-0);
  --ifm-color-emphasis-0: color-mix(in srgb, var(--fbn-light-blue), black 90%);
  --ifm-color-emphasis-100: color-mix(in srgb, var(--fbn-light-blue), black 80%);
  --ifm-color-emphasis-200: color-mix(in srgb, var(--fbn-light-blue), black 70%);
  --ifm-color-emphasis-300: color-mix(in srgb, var(--fbn-light-blue), black 60%);
  --ifm-color-emphasis-400: color-mix(in srgb, var(--fbn-light-blue), black 50%);
  --ifm-color-emphasis-500: color-mix(in srgb, var(--fbn-light-blue), black 40%);
  --ifm-color-emphasis-600: color-mix(in srgb, var(--fbn-light-blue), black 30%);
  --ifm-color-emphasis-700: color-mix(in srgb, var(--fbn-light-blue), black 20%);
  --ifm-color-emphasis-800: color-mix(in srgb, var(--fbn-light-blue), black 10%);
  --ifm-color-emphasis-900: color-mix(in srgb, var(--fbn-light-blue), black 5%);
  --ifm-color-emphasis-1000: color-mix(in srgb, var(--fbn-light-blue), black 0%);
  --ifm-hero-background-color: var(--ifm-color-emphasis-0);
  --ifm-hero-text-color: var(--ifm-color-content);
  --ifm-menu-color: var(--ifm-color-content);
  --ifm-navbar-background-color: var(--fbn-dark-teal);
  --ifm-navbar-link-color: var(--fbn-white);
  --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.3);
}

body {
  &.search-open {
    overflow: hidden !important;
  }
}

/* hide brand logo in intermediary widths to make space for header buttons */
@media screen and (max-width: 1200px) and (min-width: 996px) {
  .navbar__brand {
    display: none;
  }
}

/* put search container back into normal flow to avoid domain picker disappearing behind it in mobile view */
@media screen and (max-width: 996px) {
  .footer {
    --ifm-footer-padding-horizontal: unset;
  }

  .navbar-search-container {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar__items .navbar__brand {
    display: none;
  }
}

.error-codes {
  dd {
    margin-bottom: 1rem;
  }

  h2 {
    color: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
  }
}

.markdown {
  --ifm-link-decoration: underline;
}

.navbar__logo {
  margin: 0 0 0 8px;

  img {
    width: 160px;
  }
}

.plugin-id-api-docs {
  --ifm-container-width: unset;
  --ifm-container-width-xl: unset;

  .api-doc-notices {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;

    &__notice {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font: normal var(--ifm-h5-font-size) / var(--ifm-heading-line-height) var(--ifm-heading-font-family);

      .material-symbols-outlined {
        margin-right: 0.4em;
      }
    }
  }
}

.text--content {
  color: var(--ifm-color-content);
}
