@import '../../css//mixins.scss';
@import '@okta/okta-signin-widget/css/okta-sign-in.min.css';

.sign-in-dialog {
  @include dialog;

  padding: 0;

  .auth-container#okta-sign-in {
    margin: 0;
    background-color: var(--ifm-card-background-color);
    border-width: 0;
    box-shadow: var(--ifm-global-shadow-tl);
    color: var(--ifm-color-content);

    .auth-divider-text {
      background-color: var(--ifm-card-background-color);
    }

    .ajax-form-editor .infobox, .edit-form .infobox, .form-content-wrap .infobox, .m-form .infobox, .o-form .infobox, .read-only-form .infobox, .v-form .infobox {
      background-color: inherit;
    }

    .infobox, .infobox-md {
      border-color: var(--ifm-table-border-color);
    }

    .link, .link:active, .link:hover, .link:link, .link:visited {
      color: inherit;
    }

    .okta-form-input-field {
      background-color: var(--ifm-background-color);
      border-color: var(--ifm-table-border-color);

      input {
        color: inherit;
      }
    }

    .okta-form-label,
    .okta-form-title {
      color: inherit;
    }

    .social-auth-button.link-button {
      padding-left: 64px;
      padding-right: 0;
    }
  }

  &__dialog-close {
    @include button;

    background-color: transparent;
    border-color: transparent;

    width: 32px;
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 11;
  }
}
