.schema-sidebar-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;

  &__icon {
    height: 18px;
    width: 52px;
    flex-shrink: 0;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--ifm-color-emphasis-300);
    border-radius: 4px;
    color: var(--ifm-menu-color);
    font-size: 16px;
  }

  &__label {
    flex-grow: 1;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
