
@import '../../css//mixins.scss';

.domain-picker {
  padding-right: var(--ifm-navbar-item-padding-horizontal);

  &__dialog {
    @include dialog;
  }

  &__dialog-close {
    @include button;

    background-color: transparent;
    border-color: transparent;

    width: 32px;
    position: absolute;
    top: 1em;
    right: 1em;
  }

  &__dialog-heading {
    font-size: 24px;
  }

  &__domain {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__domain-input {
    all: unset;
    width: 100%;
    text-align: right;
  }

  &__domain-input-placeholder {
    color: var(--ifm-color-emphasis-600);
  }

  &__domain-input-wrapper {
    @include input;

    padding-right: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    cursor: text;
  }

  &__domain-submit {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 0 4px 4px 0;
  }

  &__domain-submit-icon {
    @include icon-spinner;

    position: absolute;
    right: 8px;
  }

  &__error {
    min-height: 24px;
    margin: 0;
    color: var(--ifm-color-danger);
    font-size: 14px;
    text-align: right;
  }

  &__login {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nav-button {
    @include button;

    .navbar-sidebar & {
      width: 100%;
      justify-content: center;
    }
  }

  &__nav-icon {
    @include icon-spinner;

    &_error {
      color: var(--ifm-color-danger);
    }
  }

  .navbar-sidebar & {
    position: absolute;
    bottom: var(--ifm-navbar-item-padding-vertical);
    left: var(--ifm-navbar-item-padding-horizontal);
    right: 0;
  }
}

@keyframes loading-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
