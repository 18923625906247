@import '../../css//mixins.scss';

.pagefind-ui__drawer {
  position: absolute;
  right: 0;
  padding: 16px;
  width: 500px;
  max-height: 60vh;
  background-color: white;
  overflow: auto;
}

.search-bar {
  $input-padding: 6px;

  position: relative;
  width: 260px;
  color: var(--ifm-color-content);

  &__result {
    --ifm-link-hover-color: var(--ifm-color-content);
    --ifm-link-hover-decoration: none;
  
    display: block;
    padding: 12px 24px;
    position: relative;
    border-radius: var(--ifm-button-border-radius);
    color: var(--ifm-color-content);

    &_active {
      box-shadow: 0 0 0 2px inset var(--ifm-color-info);
    }
  
    &_title-only {
      .search-bar__result-title {
        padding-bottom: 0;
      }
    }
  
    &_sub-result {
      $color: color-mix(in srgb, var(--ifm-color-content) 80%, transparent);
      --ifm-link-hover-color: $color;
  
      padding-left: 36px;
      color: $color;
      line-height: normal;
  
      .search-bar__result-title {
        margin: 0 0 4px 0;
        font-weight: normal;
      }
  
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 24px;
        height: 100%;
        border-left: 1px solid var(--ifm-color-emphasis-200);
      }
  
      & + .search-bar__result:not(&) {
        margin-top: 8px;
      }
    }

    &:hover {
      background-color: var(--ifm-hover-overlay);
    }
  }

  &__result-excerpt {
    margin: 0;
    mask-image: linear-gradient(180deg, #000 70%, transparent);
  }

  &__result-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 12px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    opacity: 0.8;
  }

  &__result-subtitle {
    margin-left: 6px;
    font-size: 12px;
    font-weight: normal;
    opacity: 0.6;
  }

  &__result-title {
    // small margin to the right to not overlap icon in top-right corner
    margin: 0 12px 0 0;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
  }

  &__result-excerpt,
  &__result-title {
    mark {
      background-color: unset;
      color: var(--ifm-color-info-dark);
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  &__results-container {
    @include panel;

    position: absolute;
    top: -$input-padding;
    left: -$input-padding;
    padding: 48px 6px 10px 6px;
    width: calc(100% + 2 * $input-padding);
    max-height: 70vh;
    display: flex;
    flex-direction: column;
  }

  &__results-fallback {
    padding-left: 24px;
  }

  &__results-filters {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 $input-padding;
    user-select: none;
  }

  &__results-filter {
    border-radius: 0.5rem;
    padding: 4px 8px;
    margin-right: 4px;
    cursor: pointer;

    &_active {
      background: var(--ifm-pills-color-background-active);
      color: var(--ifm-pills-color-active);
    }

    &:hover, &:focus-within {
      background: var(--ifm-pills-color-background-active);
    }
  }

  &__results-filter-input {
    display: none;
  }

  &__show-more {
    @include button;

    flex-shrink: 0;
    justify-content: center;
    // needs a little vertical margin to stop the border being clipped
    margin: 1px auto;
    width: 250px;

    &_active {
      border-radius: var(--ifm-button-border-radius);
      box-shadow: 0 0 0 2px inset var(--ifm-color-info);
    }

    &:disabled {
      @include interactable_disabled;
    }
  }

  &__trigger {
    @include input;

    background-color: var(--ifm-color-content-inverse);
    cursor: text;

    .material-symbols-outlined {
      padding-right: 8px;
      user-select: none;
    }
  }

  &__trigger-input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;

    &:active, &:focus {
      outline: none;
    }
  }

  &__veil {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color-mix(in srgb, var(--ifm-background-color) 80%, transparent);
    backdrop-filter: blur(4px);
    z-index: 1;
  }

  &:not(.search-bar_open) {
    @media screen and (max-width: 768px) {
      width: 185px;
    }
  }

  &_open {
    position: absolute;
    width: 600px;
    top: 13px;
    transition: width var(--ifm-transition-fast);
    z-index: 1;

    @media screen and (max-width: 996px) {
      position: fixed;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      transition: width 0ms;
    }
  }
}
