.stoplight-parsed-docs {
  @import '../../node_modules/@stoplight/elements/styles.min';

  --font-prose: var(--ifm-font-family-base);
  --font-ui: var(--ifm-font-family-base);
  --font-mono: var(--ifm-font-family-monospace);
  --font-code: var(--font-mono);
  --fs-paragraph-leading: 22px;
  --fs-paragraph: 16px;
  --fs-code: 14px;
  --fs-paragraph-small: 14px;
  --fs-paragraph-tiny: 12px;
  --lh-paragraph-leading: 1.875;
  --lh-paragraph: 1.625;
  --lh-code: 1.5;
  --lh-paragraph-small: 1.625;
  --lh-paragraph-tiny: 1.625;
  --color-code: var(--color-canvas-tint);
  --color-on-code: var(--color-text-heading);

  [data-theme=dark],
  [data-theme=dark] .sl-inverted {
    --canvas-h: 191;
    --canvas-s: 52%;
    --canvas-l: 16%;
  
    --color-canvas-50: var(--ifm-color-emphasis-200);
    --color-canvas-100: hsl(191 52% 10% / 1);
    --color-canvas-200: var(--fbn-dark-teal);
    --color-canvas-dark: hsl(191 52% 10% / 1);
    --color-canvas-pure: var(--color-canvas);
    --color-primary: var(--ifm-color-emphasis-500);
    --color-text-heading: (--ifm-color-content);
    --text-l: 90%;
  }

  [data-theme=dark] & {
    .token {
      &.string {
        color: #98c379 !important;
      }

      &.function {
        color: #4dafef !important;
      }

      &.property {
        color: #e06c69 !important;
      }

      &.punctuation {
        color: #56b6c2 !important;
      }
    }
  }

  pre {
    // suppress docusaurus styling
    background-color: unset;
    border-radius: unset;
  }

  h1.sl-text-heading {
    display: none;
  }

  .sl-text-base {
    font-size: 14px;
  }

  &_get {
    --method-colour: var(--ifm-color-success);
  }

  &_delete {
    --method-colour: var(--ifm-color-danger);
  }

  &_options {
    --method-colour: var(--ifm-color-info);
  }

  &_patch,
  &_post,
  &_put {
    --method-colour: var(--ifm-color-warning);
  }

  div[data-testid="two-column-right"] {
    width: 45%;
    max-width: 600px !important;
    margin-left: 32px;

    & > div > div:nth-child(2) {
      order: 2;
    } 
  }

  div[title^="https://"] {
    background-color: color-mix(in srgb, var(--method-colour) 10%, transparent);

    & > div:nth-child(1) {
      background-color: unset;
      border: 2px solid color-mix(in srgb, var(--method-colour) 60%, transparent);
      color: var(--ifm-color-content);
      padding: 0 10px;
      font-family: var(--ifm-font-family-base);
    }
  }

  .ParameterGrid,
  .sl-code-viewer__scroller,
  .TextRequestBody {
    max-height: unset !important;
  }

  span[data-test="property-type"] {
    opacity: 0.8;
    font-size: 0.9em;
  }

  // bandaid fix for bug in dropdown that can cause it to overflow off the bottom of the screen
  // reduces severity of the bug without impacting most users
  .sl-popover {
    max-height: 400px !important;
  }

  .sl-select-all {
    -webkit-user-select: unset;
    user-select: unset;
  }
}
