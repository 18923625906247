.card {
  --ifm-link-color: var(--ifm-color-content);
  --ifm-link-hover-color: var(--ifm-color-content);
  --ifm-link-decoration: none;
  --ifm-link-hover-decoration: none;

  width: 300px;
  height: 415px;
  padding: 35px 20px 35px 30px;
  
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 15%);
  border: 1px solid var(--ifm-color-emphasis-200);
  transition: all var(--ifm-transition-fast) ease;
  transition-property: border, box-shadow;
  margin-bottom: 8px;

  &__description {
    font-size: 16px;
  }

  &__icon {
    height: 60px;
    font-size: 40px;
    margin: 0 0 35px;
    opacity: 0.6;
  }

  &__title {
    font-size: 22px;
    margin-bottom: 1.875rem !important;
    min-height: 48px;
    white-space: normal;
  }

  &:hover {
    border-color: var(--ifm-color-primary);
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
