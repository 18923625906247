.api-sidebar-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;

  &__label {
    flex-grow: 1;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__method {
    height: 18px;
    width: 52px;
    flex-shrink: 0;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid color-mix(in srgb, var(--method-colour) 60%, transparent);
    border-radius: 4px;
    color: var(--ifm-menu-color);
    font-size: 12px;

    &_get {
      --method-colour: var(--ifm-color-success);
    }
  
    &_delete {
      --method-colour: var(--ifm-color-danger);
    }
  
    &_options {
      --method-colour: var(--ifm-color-info);
    }

    &_patch,
    &_post,
    &_put {
      --method-colour: var(--ifm-color-warning);
    }
  }

  .material-symbols-outlined {
    padding-left: 8px;
  }
}
