.landing-view {
  &__container {
    display: flex;
    flex-wrap: wrap;

    .card:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__hero {
    padding: 4rem 0;

    @media screen and (max-width: 996px) {
      padding: 2rem;
    }
  }
}
