@import '../../css//mixins.scss';

ul.menu__list {
  flex-grow: 1;
  overflow: hidden;
  margin: -8px -9px;
}

// nav.menu for desktop sidebar, .navbar-sidebar__item for mobile sidebar
nav.menu, .navbar-sidebar__item {
  display: flex;
  flex-direction: column;
}

// remove small gap below "Back to main menu" button
.navbar-sidebar__back {
  margin-bottom: 0;
}

.sidebar-items {
  position: relative;
  height: 100%;

  &__action {
    @include button;

    height: 24px;
    width: 24px;
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: var(--ifm-menu-color);

    .material-symbols-outlined {
      font-size: 18px;
    }
  }

  &__actions {
    display: flex;
    justify-content: end;
    background-color: color-mix(in srgb, var(--ifm-background-surface-color) 40%, transparent);
  }

  &__category-item {
    display: flex;
    cursor: pointer;

    &::after {
      content: 'chevron_right';
      margin-left: auto;
      font-family: 'Material Symbols Outlined';
      transform: scale(1.5);
    }

    &_active, &_active:hover {
      color: var(--ifm-menu-color-active);
    }

    &_open::after {
      transform: scale(1.5) rotate(90deg) translate(0px, 1px);
    }
  }

  &__category-item-checkbox {
    display: none;
  }

  &__category-item-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item {
    height: 32px;
  }

  &__items {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__virtual-container {
    position: relative;
    width: 100%;
  }

  .menu__link {
    transition: unset;
  }

  &__scroll-root {
    position: relative;
    height: calc(100% - 24px);
    padding: 8px 4px;
    overflow: hidden auto;
    scrollbar-gutter: stable;
  }
}

