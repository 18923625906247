@mixin button {
  @include interactable;

  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  font-weight: var(--ifm-dropdown-font-weight);
  line-height: 16px;
  cursor: pointer;
  user-select: none;
}

@mixin dialog {
  @include panel;

  margin-top: 12vh;
  min-width: 350px;
  user-select: none;

  &::backdrop {
    background-color: color-mix(in srgb, var(--ifm-background-color) 80%, transparent);
    backdrop-filter: blur(4px);
  }
}

@mixin icon-spinner($base-icon-size: 24px, $reduced-icon-size: 16px) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $base-icon-size;
  height: $base-icon-size;
  font-size: $base-icon-size;
  transition: font-size var(--ifm-transition-fast);

  &_loading {
    font-size: $reduced-icon-size;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: currentColor;
      animation: loading-spinner 2s infinite;
    }
  }
}

@mixin interactable {
  background: transparent;
  border: 1px solid var(--ifm-table-border-color);
  border-radius: var(--ifm-button-border-radius);
  transition: background var(--ifm-transition-fast);

  &:focus-within, &:focus-visible, &:active {
    border-color: var(--ifm-color-emphasis-500);
    outline: none;
  }

  &:hover:not(:focus-within, :focus-visible, :active) {
    background-color: var(--ifm-color-emphasis-200);
  }
}

@mixin interactable_disabled {
  opacity: 0.65;
  pointer-events: none;
}

@mixin input {
  @include interactable;

  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 16px 0 12px;
  color: var(--ifm-color-content);
}

@mixin panel {
  background-color: var(--ifm-card-background-color);
  border: 1px solid var(--ifm-table-border-color);
  border-radius: 10px;
  box-shadow: var(--ifm-global-shadow-tl);
  overflow: hidden;
}
